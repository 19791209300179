<!--  框架  -->
<template>
  <!-- 布局 -->
  <el-container class="main_container">
    <!--  头部  -->
    <el-header>
      <el-row class="navigation">
        <!--  Logo  -->
        <el-col class="navigation_col1">
          <img
            src="../assets/index_images/headerLogo.png"
            class="header-img"
            @click="goToIndex()"
          />
        </el-col>
        <!--  导航菜单  -->
        <el-col class="navigation_col2">
          <el-menu
            :default-active="path"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="/index">首页</el-menu-item>
            <el-menu-item index="/company_introduction">公司介绍</el-menu-item>
            <el-menu-item index="/certification_services"
              >认证业务</el-menu-item
            >
            <el-menu-item index="/non_certified_services"
              >非认证业务</el-menu-item
            >
            <el-menu-item index="/information_express">资讯快递</el-menu-item>
            <el-menu-item index="/public_documents">公开文件</el-menu-item>
            <el-menu-item index="/learning_park">学习乐园</el-menu-item>
            <el-menu-item index="/contact_us">联系我们</el-menu-item>
          </el-menu>
        </el-col>

        <!--  小屏导航  -->
        <span
          class="el-icon-s-grid navigation_small_icon"
          @click="openClose"
        ></span>
      </el-row>
    </el-header>

    <!--  小屏状态下的导航栏  -->
    <div class="navigation_small" v-show="show" style="display: none">
      <el-menu
        :default-active="path"
        class="el-menu-vertical-demo"
        @select="handleSelect"
      >
        <el-menu-item @click="notshow()" index="/index">首页</el-menu-item>
        <el-menu-item @click="notshow()" index="/company_introduction"
          >公司介绍</el-menu-item
        >
        <el-menu-item @click="notshow()" index="/certification_services"
          >认证业务</el-menu-item
        >
        <el-menu-item @click="notshow()" index="/non_certified_services"
          >非认证业务</el-menu-item
        >
        <el-menu-item @click="notshow()" index="/information_express"
          >资讯快递</el-menu-item
        >
        <el-menu-item @click="notshow()" index="/public_documents"
          >公开文件</el-menu-item
        >
        <el-menu-item @click="notshow()" index="/learning_park"
          >学习乐园</el-menu-item
        >
        <el-menu-item @click="notshow()" index="/contact_us"
          >联系我们</el-menu-item
        >
      </el-menu>
    </div>

    <!--  主体  -->
    <el-main><router-view /></el-main>

    <!--  脚部  -->
    <el-footer>
      <div class="footer">
        <el-row>
          <el-col
            :sm="24"
            :md="10"
            style="text-align: center; padding-bottom: 20px"
          >
            <img
              src="../assets/index_images/footerLogo.png"
              height="35"
              class="bottom_logo"
              @click="goToIndex()"
            />
          </el-col>
          <el-col :xs="8" :sm="8" :md="2" class="footer_right_text">
            <p class="footer_right_p1">认证业务</p>
            <p @click="certificationSystem()" class="bottom_link">认证体系</p>
            <p @click="certificationProcess()" class="bottom_link">认证流程</p>
            <p @click="chargingStandard()" class="bottom_link">收费标准</p>
          </el-col>
          <el-col :xs="8" :sm="8" :md="2" class="footer_right_text">
            <p class="footer_right_p1">非认证业务</p>
            <p @click="twoPartyEvaluation()" class="bottom_link">二方评价</p>
            <p @click="standardSetting()" class="bottom_link">标准制定</p>
            <p @click="technicalTraining()" class="bottom_link">技术培训</p>
          </el-col>
          <el-col :xs="8" :sm="8" :md="2" class="footer_right_text">
            <p class="footer_right_p1">资讯快递</p>
            <p @click="importantNews()" class="bottom_link">公司要闻</p>
            <p @click="noticeNotice()" class="bottom_link">通知公告</p>
          </el-col>
          <el-col :sm="24" :md="5" class="footer_right_text">
            <p class="footer_right_p1">联系我们</p>
            <!-- <p>天津鑫锐认证有限公司</p> -->
            <p>鑫锐认证有限公司</p>
            <p>邮 编：300000</p>
            <p>电话：022-22722222</p>
            <p>通讯地址：天津市河西区美年广场4号楼-1704-28</p>
          </el-col>
          <el-col :md="3"> &nbsp; </el-col>
        </el-row>
        <hr />
        <div class="footer_footer">
          <p>Copyright @www.xinruirenzheng.com</p>
          <p class="footer_footer_link" @click="footerLink()">
            津ICP备2022003993号-1
          </p>
          <div class="footer_footer_link" style="width: 300px; margin: 0 auto">
            <a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12010302002323"
              style="
                display: inline-block;
                text-decoration: none;
                height: 20px;
                line-height: 20px;
              "
            >
              <img src="" style="float: left" />
              <p
                style="
                  float: left;
                  height: 20px;
                  line-height: 20px;
                  margin: 0px 0px 0px 5px;
                "
              >
                津公网安备 12010302002323号
              </p>
            </a>
          </div>
          <a data-v-064a7ba8="" :href="url" target="_blank"
            >Powered by QiHengYun.com</a
          >
          <p>版本号：1.0.0</p>
        </div>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  name: "layout",
  data() {
    return {
      show: false,
      path: "",
      url:
        process.env.NODE_ENV === "production"
          ? "https://www.xinruirenzheng.com/xrrz_admin"
          : "https://d.honyoy.com/xrrz_admin",
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.path = window.sessionStorage.getItem("path") || "/index";
      },
      immediate: true,
    },
  },
  methods: {
    handleSelect(key, keyPath) {
      this.path = key;
      this.$router.push(key);
      window.sessionStorage.setItem("path", keyPath);
    },
    openClose() {
      if (this.show === true) {
        this.show = false;
      } else {
        this.show = true;
      }
    },
    goToIndex(key, keyPath) {
      this.$router.push("/index");
      this.path = "/index";
      window.sessionStorage.setItem("path", keyPath);
      document.documentElement.scrollTop = 0; // 自动回到顶部
    },
    certificationSystem() {
      this.$router.push({
        path: "management_system_certification",
        query: { path: "quality" },
      }); // 认证体系
      this.path = "/certification_services";
      window.sessionStorage.setItem("path", this.path);
      document.documentElement.scrollTop = 0; // 自动回到顶部
    },
    certificationProcess() {
      this.$router.push({
        path: "certification_process",
        query: { path: "certification_process" },
      }); // 认证流程
      this.path = "/certification_services";
      window.sessionStorage.setItem("path", this.path);
      document.documentElement.scrollTop = 0; // 自动回到顶部
    },
    chargingStandard() {
      this.$router.push({
        path: "charging_standard",
        query: { path: "charging_standard" },
      }); // 收费标准
      this.path = "/certification_services";
      window.sessionStorage.setItem("path", this.path);
      document.documentElement.scrollTop = 0; // 自动回到顶部
    },
    twoPartyEvaluation() {
      this.$router.push({
        path: "/non_certified_services",
        query: { activeName: "first" },
      }); // 二方评价
      this.path = "/non_certified_services";
      window.sessionStorage.setItem("path", this.path);
      document.documentElement.scrollTop = 0; // 自动回到顶部
    },
    standardSetting() {
      this.$router.push({
        path: "/non_certified_services",
        query: { activeName: "second" },
      }); // 标准制定
      this.path = "/non_certified_services";
      window.sessionStorage.setItem("path", this.path);
      document.documentElement.scrollTop = 0; // 自动回到顶部
    },
    technicalTraining() {
      this.$router.push({
        path: "/non_certified_services",
        query: { activeName: "third" },
      }); // 技术培训
      this.path = "/non_certified_services";
      window.sessionStorage.setItem("path", this.path);
      document.documentElement.scrollTop = 0; // 自动回到顶部
    },
    importantNews() {
      this.$router.push({
        path: "/information_express",
        query: { activeName: "first" },
      }); // 公司要闻
      this.path = "/information_express";
      window.sessionStorage.setItem("path", this.path);
      document.documentElement.scrollTop = 0; // 自动回到顶部
    },
    noticeNotice() {
      this.$router.push({
        path: "/information_express",
        query: { activeName: "second" },
      }); // 通知公告
      this.path = "/information_express";
      window.sessionStorage.setItem("path", this.path);
      document.documentElement.scrollTop = 0; // 自动回到顶部
    },
    notshow() {
      this.show = false;
    },
    footerLink() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.main_container {
  width: 100%;

  // 头部
  .el-header {
    height: 80px !important;
    background-color: #0f3572;
    padding-top: 20px;
    .header-img {
      height: 40px;
    }
    .header-img:hover {
      cursor: pointer;
    }
    .navigation {
      position: relative;
    }
    .navigation_small {
      position: fixed;
      top: 0;
    }
    .navigation_small_icon {
      width: 30px;
      height: 30px;
      border-radius: 3px;
      background-color: rgba(255, 255, 255, 0.7);
      text-align: center;
      line-height: 30px;
      position: absolute;
      right: 2vw;
      top: 4px;
      display: none;
    }
    .navigation_small_icon:hover {
      color: #0071ff;
      cursor: pointer;
    }

    /*  小屏导航栏  */

    @media screen and (min-width: 1400px) {
      .navigation_col1 {
        width: 32%;
        text-align: right;
      }
      .navigation_col2 {
        width: 68%;
        padding-left: 7%;
      }
    }
    @media screen and (max-width: 1400px) {
      .navigation_col1 {
        width: 30%;
        text-align: center;
      }
      .navigation_col2 {
        width: 70%;
        padding-left: 5%;
      }
    }
    @media screen and (max-width: 1300px) {
      .navigation_col1 {
        width: 22%;
        text-align: center;
      }
      .navigation_col2 {
        width: 78%;
        padding-left: 6%;
      }
    }
    @media screen and (max-width: 1200px) {
      .navigation_col1 {
        width: 100%;
        text-align: center;
      }
      .navigation_col2 {
        display: none;
      }
      .navigation_small_icon {
        display: block;
      }
    }
  }

  // 主体
  .el-main {
    background-color: #e9eef3;
  }

  // 脚部
  .el-footer {
    width: 100%;
    margin: 0;
    padding: 0;
    .bottom_logo {
      margin-top: 25px;
    }
    .bottom_logo:hover {
      cursor: pointer;
    }
    p {
      margin: 7px auto;
    }
    .footer {
      width: 100%;
      background-color: #4f4f4f;
    }
    .footer_right_text {
      color: white;
      font-size: 14px;
      padding-top: 20px;
      padding-bottom: 10px;
    }
    .footer_right_p1 {
      font-size: 18px;
      padding-bottom: 5px;
    }
    .footer_footer {
      color: white;
      text-align: center;
      padding: 10px 0;
      font-size: 14px;
    }
    .footer_footer_link:hover {
      cursor: pointer;
    }
    a:hover,
    a:visited,
    a:link,
    a:active {
      color: white;
      text-decoration: none;
    }
    a {
      color: white;
    }
    .bottom_link:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 991px) {
      .footer_right_text {
        padding-left: 0;
        text-align: center;
      }
      .footer_right_p1 {
        font-size: 18px;
      }
      .footer_footer {
        font-size: 14px;
      }
    }
  }
  // 修改 element ui 自带样式

  /*  导航菜单  */

  /*  导航背景颜色  */
  .el-menu {
    background-color: rgba(0, 0, 0, 0);
  }

  /deep/ .el-menu--horizontal > .el-menu-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    border-bottom: 2px solid white;
    transition: 0.2s all linear;
  }

  /deep/ .el-menu--horizontal > .el-menu-item:hover:before {
    width: 100%;
    left: 0;
  }

  /deep/ .el-menu--horizontal > .el-menu-item:hover ~ ::before {
    left: 0;
  }

  /deep/ .el-menu--horizontal > .el-menu-item {
    height: 45px;
  }

  /*  点击后的底部底部border和点击后的字体颜色  */
  .el-menu--horizontal > .el-menu-item.is-active {
    color: white !important;
    border-bottom: 2px solid white;
  }

  /*  li hover后的背景颜色  */
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  /*  字体的大小，底部下划线和颜色  */
  .el-menu--horizontal > .el-menu-item {
    color: white !important;
    border-bottom: none;
    font-size: 17px;
    line-height: 40px;
  }

  /*  每个item的间距  */
  .el-menu-item {
    padding: 0;
    margin: 0 17px;
  }

  /*  底部下划线  */
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
}
</style>