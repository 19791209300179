import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/global.css'  // 添加全局样式
import BaiduMap from 'vue-baidu-map'
import bus from './util/bus'

Vue.use(bus)

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'L7eX3FhbfVonhnDB3zGu1fGeaiyHzLG7'
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
