import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/layout";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "layout",
    component: Layout,
    redirect: "/index",
  },
  {
    path: "/layout",
    component: Layout,
    redirect: "/index",
    children: [
      { path: "/index", component: () => import("../components/index/index") }, // 主页
      {
        path: "/certificateSelect",
        component: () => import("../components/new/certificateSelect"),
      }, // 非认证类证书查询
      {
        path: "/certificateMain",
        component: () => import("../components/new/certificateMain"),
      }, // 非认证类证书查询详情页
      {
        path: "/company_introduction",
        component: () =>
          import("../components/company_introduction/company_introduction"),
      }, // 公司介绍
      {
        path: "/certification_services", // 认证服务
        component: () =>
          import("../components/certification_services/certification_services"),
        redirect: "/management_system_certification",

        children: [
          {
            path: "/management_system_certification", // 管理体系认证
            component: () =>
              import(
                "../components/certification_services/children/management_system_certification"
              ),
            redirect: "/quality",
            children: [
              {
                path: "/quality",
                component: () =>
                  import(
                    "../components/certification_services/children/child/quality"
                  ),
              }, // 质量管理体系认证
              {
                path: "/occupation",
                component: () =>
                  import(
                    "../components/certification_services/children/child/occupation"
                  ),
              }, // 职业健康安全管理体系认证
              {
                path: "/environment",
                component: () =>
                  import(
                    "../components/certification_services/children/child/environment"
                  ),
              }, // 环境管理体系认证
            ],
          },

          {
            path: "/certification_process",
            component: () =>
              import(
                "../components/certification_services/children/certification_process"
              ),
          }, // 认证流程
          {
            path: "/charging_standard",
            component: () =>
              import(
                "../components/certification_services/children/charging_standard"
              ),
          }, // 收费标准
        ],
      },
      {
        path: "/non_certified_services",
        component: () =>
          import("../components/non_certified_services/non_certified_services"),
      }, // 非认证服务
      {
        path: "/information_express",
        component: () =>
          import("../components/information_express/information_express"),
      }, // 资讯快递
      {
        path: "/company_news_details_page",
        component: () => import("../components/company_news_details_page"),
      },
      {
        path: "/public_documents",
        component: () =>
          import("../components/public_documents/public_documents"),
      }, // 公开文件
      {
        path: "/learning_park",
        component: () => import("../components/learning_park/learning_park"),
      }, // 学习乐园
      {
        path: "/contact_us",
        component: () => import("../components/contact_us/contact_us"),
      }, // 联系我们
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
